<template>
    <div>
        <div class="row">
            <div class="col my-auto">
                <h3>Hai <span v-if="userName">, {{ userName }}</span> 👋🏻</h3>
                <p>mau donasi apa hari ini ?</p>
            </div>
            <div class="col-auto my-auto">
                <img v-if="userName" style="max-height: 60px;" class="img-fluid rounded-circle"
                    src="https://indonesiabaik.id/public/uploads/user/207/AVATAR-02.png" alt="">
            </div>
        </div>

        <div class="live__scroll">
            <div class="row mt-4">
                <div class="col-10 live__scroll--box" v-for="campaign in campaigns" :key="campaign.id">
                    <CampaignComponent :payload="campaign" @click.native="toDetailCampaign(campaign.id)" />
                </div>
            </div>
        </div>


        <section class="mt-4">
            <div class="row mt-4">
                <div class="col">
                    <h5>Donasi Anda</h5>
                </div>
                <div class="col-auto">
                    <router-link to="/donations" class="color-primer">Lihat Semua</router-link>
                </div>
            </div>

            <DonationList v-for="donation in donations" :key="donation.id" :payload="donation" />
        </section>

        <section>
            <div class="row mt-4">
                <div class="col">
                    <h5>Rekomendasi Donasi</h5>
                </div>

            </div>

            <BannerComponent :payloads="banners" />
        </section>

        <section>
            <div class="row mt-4">
                <div class="col">
                    <h5>Doa Donatur</h5>
                </div>

            </div>

            <div class="live__scroll">
                <div class="row mt-4">
                    <!-- <div class="col-6 live__scroll--box">
                        <div class="px-4 py-2 rounded">
                            <div class="row mt-2">
                                <div class="col p-2 text-truncate">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <img :src="doaAsset" class="img-fluid" alt="">
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col p-2 text-truncate">
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-10 live__scroll--box" v-for="doa in doas" :key="doa.id">
                        <DoaComponent :payload="doa" />
                    </div>
                </div>
            </div>

        </section>

        <br>
        <br>
        <br>

        <NavbarComponent />
    </div>
</template>
<script>
import BannerComponent from '../../components/v2/BannerComponent.vue';
import CampaignComponent from '../../components/v2/CampaignComponent.vue';
import DoaComponent from '../../components/v2/DoaComponent.vue';
import DonationList from '../../components/v2/DonationList.vue';
import NavbarComponent from '../../components/v2/NavbarComponent.vue';
import doaAsset from '../../assets/doa.png';

import axios from 'axios';

export default {
    components: {
        NavbarComponent,
        CampaignComponent,
        DonationList,
        BannerComponent,
        DoaComponent,
    },
    data() {
        return {
            doaAsset,

            userName: localStorage.getItem('userName'),

            campaigns: [],
            donations: [],
            banners: [],
            doas: [],
        }
    },

    beforeMount() {
        this.getCampaigns();
        this.getDonations();
        this.getBanners();
        this.getDoa();
    },

    methods: {
        toDetailCampaign(id) {
            this.$router.push('/campaign/' + id);
        },

        getDoa() {
            axios.get(process.env.VUE_APP_API + 'doa', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
                params: {
                    limit: 10,
                }
            }).then((resp) => {
                if(resp.status == 200) {
                    const data = resp.data.data;

                    data.forEach(element => {
                        this.doas.push(element);
                    });
                }
            });
        },

        getBanners() {
            axios.get(process.env.VUE_APP_API + 'banner', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
                params: {
                    limit: 10,
                }
            }).then((resp) => {
                if(resp.status == 200) {
                    const data = resp.data.data;

                    data.forEach(element => {
                        this.banners.push({
                            id: element.id,
                            link: element.link,
                            description: element.description,
                        });
                    });
                }
            });
        },

        getCampaigns() {
            axios.get(process.env.VUE_APP_API + 'campaigns', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
                params: {
                    limit: 10,
                }
            }).then((resp) => {
                if(resp.status == 200) {
                    const data = resp.data.data;

                    data.forEach(element => {
                        this.campaigns.push({
                            id: element.id,
                            campaign_title: element.campaign_title,
                            location: element.location,
                            description: element.description,
                            goal_amount: element.goal_amount,
                            funded: element.funded,
                            percentage_funded: element.percentage_funded,
                            image_url: element.path,
                        });
                    });
                }
            })
        },

        getDonations() {
            axios.get(process.env.VUE_APP_API + 'donation', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
                params: {
                    limit: 3,
                }
            }).then((resp) => {
                if(resp.status == 200) {
                    const data = resp.data.data;

                    data.forEach(element => {
                        this.donations.push({
                            id: element.id,
                            campaign_title: element.campaign_title,
                            campaign_img: element.campaign_img,
                            status: element.status,
                            location: element.location,
                        });
                    });
                }
            })
        }
    }
}

</script>

<style>
.live__scroll {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.live__scroll .row {
    display: block;
}

.live__scroll .live__scroll--box {
    display: inline-block;
    float: none;
}
</style>