<template>
    <div class="row" style="border-radius: 6px;">
        <div class="col p-4 rounded">
            <div class="row bg-white px-2 py-2">
                <div class="col-auto">
                    <img style="border-radius: 6px; width: 60px; height: 60px; object-position: center;" :src="payload.campaign_img" class="img-fluid" alt="">
                </div>
                <div class="col my-auto text-truncate">
                    <div>
                        <div>
                            <h5 class="text-truncate">{{ payload.campaign_title }}</h5>
                        </div>
                        <div class="text-muted">
                            {{ payload.location }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['payload'],
    name: 'DonationList',
}
</script>